import axios from "axios";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import router from "../router";
dayjs.extend(utc);

const apiClient = axios.create({
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  },
  //some of the bigger files might take a long time to upload for admins on a slow connection
  timeout: 120000
});

const routeToLocalStorage = function (fullCurrentRoute) {
  try {
    if (typeof Storage !== "undefined") {
      let routeToStore = {};
      routeToStore.name = JSON.parse(JSON.stringify(fullCurrentRoute.name));
      routeToStore.params = JSON.parse(JSON.stringify(fullCurrentRoute.params));
      routeToStore.query = JSON.parse(JSON.stringify(fullCurrentRoute.query));

      window.localStorage.setItem("preInteruptRoute", JSON.stringify(routeToStore));
    }
  } catch (e) {
    //console.log(e);
  }
};

apiClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if ((error.response && 401 === error.response.status) || (error.response && 419 === error.response.status)) {
      if (router.currentRoute.name === "Login") {
        return Promise.reject(error);
      }
      router
        .push({
          name: "Login"
        })
        .catch(() => {
          throw new Error("There was a problem redirecting to the Login page.");
        });
    } else if (error.response && 503 === error.response.status) {
      routeToLocalStorage(router.currentRoute.value);
      router
        .push({
          name: "SiteMaintenance"
        })
        .catch(() => {
          throw new Error("There was a problem redirecting to the Maintenance page.");
        });
    } else if (error.toJSON().message === "Network Error") {
      routeToLocalStorage(router.currentRoute.value);
      router
        .push({
          name: "NetworkConnectivity"
        })
        .catch(() => {
          throw new Error("There was a problem redirecting to the NetworkIssues page.");
        });
    }

    return Promise.reject(error);
  }
);

export default {
  urlStart: "/api/",
  atAuthLevel(level) {
    switch (level) {
      case "mixedAdmin":
        this.urlStart = "/api/mixed-admin/";
        break;
      case "superAdmin":
        this.urlStart = "/api/admin/";
        break;
      case "groupingAdmin":
        this.urlStart = "/api/grouping-admin/";
        break;
      case "subgroupAdmin":
        this.urlStart = "/api/subgroup-admin/";
        break;
      case "virtualGroupAdmin":
        this.urlStart = "/api/virtual-group-admin/";
        break;
      default:
        this.urlStart = "/api/";
    }
    return this;
  },

  getCsrf() {
    return apiClient.get("/sanctum/csrf-cookie");
  },

  login(params) {
    return apiClient.post("/login", params);
  },

  logout() {
    return apiClient.post("/logout");
  },

  //the show method has rendered emails - therefore web route not api - this web route does require admin auth though.
  getMessagePreviews() {
    return apiClient.get("/message-previews");
  },

  approveMessagePreview(messagePreviewCode) {
    return apiClient.patch(`/message-previews/${messagePreviewCode}/approve`);
  },

  getNotificationData(notificationClass) {
    return apiClient.get(`/notification-preview/${notificationClass}/data`);
  },

  //User
  forgottenPassword(params) {
    return apiClient.post("/password/email", params);
  },

  resetPassword(params) {
    return apiClient.post("/password/reset", params);
  },

  getInvite(token) {
    return apiClient.get(`/invite/${token}`);
  },

  acceptInvite(token) {
    return apiClient.post(`/invite-accept/${token}`);
  },

  getUser() {
    return apiClient.get(`/api/user`);
  },

  fetchUserMultiContext(userId) {
    return apiClient.get(`/api/user/${userId}/multi-context`);
  },

  // we can use this where we expect them to be logged in.
  checkLoggedInStatus() {
    return apiClient.get(`/api/logged-in`);
  },

  //we use this to check if they're already logged in on the login page
  //this check returns data rather than a 401 if they're not logged in
  checkLoggedIn() {
    return apiClient.get(`/logged-in`);
  },

  getMyCourses() {
    return apiClient.get(`/api/my-courses`);
  },

  getResources() {
    return apiClient.get(`/api/my-resources`);
  },

  getModuleData(courseId, courseType, groupId) {
    if (groupId) {
      return apiClient.get(`/api/module-data/${courseId}/${courseType}/${groupId}`);
    } else {
      return apiClient.get(`/api/module-data/${courseId}/${courseType}`);
    }
  },

  getCourseLaunchData(polyCourseId, courseType, groupId) {
    if (groupId) {
      return apiClient.get(`/api/course-launch-data/${polyCourseId}/${courseType}/${groupId}`);
    } else {
      return apiClient.get(`/api/course-launch-data/${polyCourseId}/${courseType}`);
    }
  },

  fetchScoUser(scoUserId) {
    return apiClient.get(`/api/sco-user/${scoUserId}`);
  },

  fetchScoUserResults(scoUserId) {
    return apiClient.get(`/api/sco-user/${scoUserId}/results`);
  },

  //exists in ScormApiService too
  finish(scoUserId, scoUser) {
    //when the storyline bug is fixed, we can remove this
    //IF a user exits and resumes a second attempt, then Storyline buggily sends the question bank questions
    //the user wasn't asked but without a studend_response, e.g. user is supposed to be asked 26 of 76 questions
    //Storyline sends 50 questions without a student_response, so we have to filter them out
    if (scoUser.interactions) {
      let filteredInteractions = {};
      Object.keys(scoUser.interactions).forEach((key) => {
        if (scoUser.interactions[key].student_response) {
          filteredInteractions[key] = scoUser.interactions[key];
        }
      });
      scoUser.interactions = filteredInteractions;
    }

    return apiClient.post(`/api/sco-user/${scoUserId}/finish`, scoUser);
  },

  checkSessionToken(scoUserId) {
    return apiClient.get(`/api/sco-user/${scoUserId}/check-token`);
  },

  postFeedback(scoUserId, feedback) {
    return apiClient.post(`/api/sco-user/${scoUserId}/feedback`, feedback);
  },

  saveVlPledFeedback(virtualGroupId, params) {
    return apiClient.post(`/api/virtual-group/${virtualGroupId}/feedback`, params);
  },

  sendScoUserAudit(scoUserId, params) {
    return apiClient.post(`/api/sco-user/${scoUserId}/audit`, params);
  },

  clearSessionToken(scoUserId) {
    return apiClient.post(`/api/sco-user/${scoUserId}/clear-session-token`);
  },

  postAskThePharmacist(payload) {
    return apiClient.post(`/api/ask-the-pharmacist`, payload);
  },

  getDeliverables() {
    // return apiClient.get('/api/deliverables');
    return new Promise((resolve) => {
      let response = {
        data: {
          deliverables: [
            {
              id: 1,
              code: "e_learning",
              title: "E-Learning",
              light_icon_url: "/storage/deliverables/light/e_learning.png",
              dark_icon_url: "/storage/deliverables/dark/e_learning.png",
              pled_only: 0
            },
            {
              id: 2,
              code: "distance_learning",
              title: "Distance Learning",
              light_icon_url: "/storage/deliverables/light/distance_learning.png",
              dark_icon_url: "/storage/deliverables/dark/distance_learning.png",
              pled_only: 0
            },
            {
              id: 3,
              code: "virtual_learning",
              title: "Virtual Learning",
              light_icon_url: "/storage/deliverables/light/virtual_learning.png",
              dark_icon_url: "/storage/deliverables/dark/virtual_learning.png",
              pled_only: 1
            },
            {
              id: 4,
              code: "face_to_face_learning",
              title: "Face to Face",
              light_icon_url: "/storage/deliverables/light/face_to_face.png",
              dark_icon_url: "/storage/deliverables/dark/face_to_face.png",
              pled_only: 1
            },
            {
              id: 5,
              code: "online_interactive_workbook",
              title: "Online Interactive Workbook",
              light_icon_url: "/storage/deliverables/light/distance_learning.png",
              dark_icon_url: "/storage/deliverables/dark/distance_learning.png",
              pled_only: 0
            }
          ]
        }
      };

      resolve(response);
    });
  },

  getCertificateTypes() {
    return apiClient.get(`/certificates/types`);
  },

  getCertificateIcons() {
    return apiClient.get(`/certificates/icons`);
  },

  getCertificateFooterMessages() {
    return apiClient.get(`/certificates/footer-messages`);
  },

  getPaperCourseCertificateSettings(paperCourseId) {
    return apiClient.get(`/certificates/paper-course/${paperCourseId}/settings`);
  },

  getAbstractCourseCertificateSettings(abstractCourseId) {
    return apiClient.get(`/certificates/abstract-course/${abstractCourseId}/settings`);
  },

  processNameChangeRequest(tokenId, tokenKey, action) {
    return apiClient.post(`/process-name-change-request/${tokenId}/${tokenKey}/${action}`);
  },

  // ************************************************
  // ************************************************
  // the following need to be called via atAuthLevel
  // ************************************************
  // ************************************************

  getInactiveGroups() {
    return apiClient.get(this.urlStart + `inactive-groups`);
  },

  updateMessagePreviewTypeSequence(id, type, sequence) {
    return apiClient.patch(this.urlStart + `message-preview-sequence/${type}/${id}`, { sequence });
  },

  getNotifications() {
    return apiClient.get(this.urlStart + `notifications`);
  },

  markNotificationRead(notificationId) {
    return apiClient.post(this.urlStart + `notifications/${notificationId}/mark-read`);
  },

  markAllNotificationsRead() {
    return apiClient.post(this.urlStart + `notifications/mark-all-read`);
  },

  getProjectRequestStatusDictionaries() {
    return apiClient.get(this.urlStart + `project-request-status-dictionaries`);
  },

  getOrganisersProjectRequests() {
    return apiClient.get(this.urlStart + `organisers/project-requests`);
  },

  getOrganisersSubgroupProjectRequests(subgroupId) {
    return apiClient.get(this.urlStart + `organisers/subgroup/${subgroupId}/project-requests`);
  },

  getAvailableTimesOfInterest(projectRequestId) {
    return apiClient.get(this.urlStart + `organisers/project-requests/${projectRequestId}/available-times-of-interest`);
  },

  getAvailableDatesForTwoInOneDay(twoInOneDayId) {
    return apiClient.get(this.urlStart + `organisers/two-in-one-day/${twoInOneDayId}/available-dates`);
  },

  getOrganisersTwoInOneDay(twoInOneDayId) {
    return apiClient.get(this.urlStart + `organisers/two-in-one-day/${twoInOneDayId}`);
  },

  getOrganisersProjectRequest(projectRequestId) {
    return apiClient.get(this.urlStart + `organisers/project-requests/${projectRequestId}`);
  },

  createBookingRequest(groupId, params) {
    return apiClient.post(this.urlStart + `organisers/project-requests/${groupId}`, params);
  },

  getBookingProjects() {
    return apiClient.get(this.urlStart + `bookings/projects`);
  },

  getBookingProject(projectId) {
    return apiClient.get(this.urlStart + `bookings/projects/${projectId}`);
  },

  createBookingProject(params) {
    return apiClient.post(this.urlStart + `bookings/project`, params);
  },

  updateBookingProject(projectId, params) {
    return apiClient.patch(this.urlStart + `bookings/project/${projectId}`, params);
  },

  createBookingProjectRequest(projectId, params) {
    return apiClient.post(this.urlStart + `bookings/project/${projectId}/request`, params);
  },

  createBookingProjectTwoInOneDayRequest(projectId, params) {
    return apiClient.post(this.urlStart + `bookings/project/${projectId}/two-in-one-day`, params);
  },

  updateBookingProjectRequest(projectRequestId, params) {
    return apiClient.patch(this.urlStart + `bookings/project/request/${projectRequestId}`, params);
  },

  createMultiDayProjectRequests(projectId, params) {
    return apiClient.post(this.urlStart + `bookings/project/${projectId}/multi-day-request`, params);
  },

  getPotentialTrainersForMultiDay(multiDayId) {
    return apiClient.get(this.urlStart + `bookings/project/multi-day/${multiDayId}/potential-trainers`);
  },

  narrowTrainerSelectionForMultiDay(multiDayId, trainerId) {
    return apiClient.post(
      this.urlStart + `bookings/project/multi-day/${multiDayId}/narrow-trainer-selection/${trainerId}`
    );
  },

  lockTrainerForMultiDay(multiDayId, trainerId) {
    return apiClient.post(this.urlStart + `bookings/project/multi-day/${multiDayId}/lock-trainer/${trainerId}`);
  },

  askOrganiserToChooseDatesForMultiDay(multiDayId) {
    return apiClient.post(this.urlStart + `bookings/project/multi-day/${multiDayId}/ask-organiser-to-book`);
  },

  remindOrganiserToChooseDatesForMultiDay(multiDayId) {
    return apiClient.post(this.urlStart + `bookings/project/multi-day/${multiDayId}/remind-organiser-to-book`);
  },

  tellOrganiserNothingIsAvailableForMultiDay(multiDayId) {
    return apiClient.post(
      this.urlStart + `bookings/project/multi-day/${multiDayId}/tell-organiser-nothing-is-available`
    );
  },

  getProjectRequestToEdit(projectRequestId) {
    return apiClient.get(this.urlStart + `bookings/project/request/${projectRequestId}/edit`);
  },

  getProjectRequestToManage(projectRequestId) {
    return apiClient.get(this.urlStart + `bookings/project/request/${projectRequestId}/manage`);
  },

  getDetailedDrillDownStatsForSelectedTimeOfInterest(projectRequestId) {
    return apiClient.get(this.urlStart + `bookings/project/request/${projectRequestId}/detailed-drill-down-stats`);
  },

  getTwoInOneDay(twoInOneDayId) {
    return apiClient.get(this.urlStart + `bookings/project/two-in-one-day/${twoInOneDayId}/manage`);
  },

  getMultiDay(multiDayId) {
    return apiClient.get(this.urlStart + `bookings/project/multi-day/${multiDayId}/manage`);
  },

  getTrainerBookingConfirmationEnquiry(projectRequestId) {
    return apiClient.get(
      this.urlStart + `bookings/project/request/${projectRequestId}/trainer-booking-confirmation-enquiry`
    );
  },

  sendTrainerBookingConfirmationReminder(projectRequestId) {
    return apiClient.post(
      this.urlStart + `bookings/project/request/${projectRequestId}/trainer-booking-confirmation-enquiry-reminder`
    );
  },

  sendTrainerBookingConfirmationReminderForTwoInOneDay(twoInOneDayId) {
    return apiClient.post(
      this.urlStart + `bookings/project/two-in-one-day/${twoInOneDayId}/trainer-booking-confirmation-enquiry-reminder`
    );
  },

  manuallyHandleTrainerBookingConfirmation(projectRequestId, status) {
    return apiClient.post(
      this.urlStart + `bookings/project/request/${projectRequestId}/manually-handle-trainer-booking-confirmation`,
      { status }
    );
  },

  manuallyHandleTrainerBookingConfirmationForTwoInOneDay(twoInOneDayId, status) {
    return apiClient.post(
      this.urlStart + `bookings/project/two-in-one-day/${twoInOneDayId}/manually-handle-trainer-booking-confirmation`,
      { status }
    );
  },

  bookTrainerForPreselectedTime(projectRequestId, trainerId) {
    return apiClient.post(this.urlStart + `bookings/project/request/${projectRequestId}/book-trainer/${trainerId}`);
  },

  bookTrainerForTwoInOneDay(twoInOneDayId, trainerId) {
    return apiClient.post(this.urlStart + `bookings/project/two-in-one-day/${twoInOneDayId}/book-trainer/${trainerId}`);
  },

  askTrainerToConfirmCanBeBooked(projectRequestId, trainerId) {
    return apiClient.post(
      this.urlStart + `bookings/project/request/${projectRequestId}/ask-trainer-to-confirm-can-be-booked/${trainerId}`
    );
  },

  askTrainerToConfirmCanBeBookedForTwoInOneDay(twoInOneDayId, trainerId) {
    return apiClient.post(
      this.urlStart +
        `bookings/project/two-in-one-day/` +
        `${twoInOneDayId}/ask-trainer-to-confirm-can-be-booked/${trainerId}`
    );
  },

  askTrainerToConfirmBookingForMultiDay(multiDayId) {
    return apiClient.post(
      this.urlStart + `bookings/project/multi-day/` + `${multiDayId}/ask-trainer-to-confirm-can-be-booked`
    );
  },

  remindTrainerToConfirmBookingForMultiDay(multiDayId) {
    return apiClient.post(
      this.urlStart + `bookings/project/multi-day/` + `${multiDayId}/remind-trainer-to-confirm-can-be-booked`
    );
  },

  clearSelectedDatesForMultiDay(multiDayId) {
    return apiClient.post(this.urlStart + `bookings/project/multi-day/` + `${multiDayId}/clear-selected-dates`);
  },

  cancelMultiDayBookingEnquiry(multiDayId) {
    return apiClient.post(this.urlStart + `bookings/project/multi-day/` + `${multiDayId}/cancel-booking-enquiry`);
  },

  storeSelectedTime(projectRequestId, timesOfInterestId, startTimeDate, ignoreConflictWarning) {
    return apiClient.post(this.urlStart + `organisers/project-request/${projectRequestId}/store-selected-time`, {
      timesOfInterestId,
      startTimeDate,
      ignoreConflictWarning
    });
  },

  getProjectRequestDetails(projectRequestId) {
    return apiClient.get(this.urlStart + `organisers/project-requests/${projectRequestId}/details`);
  },

  storeSelectedTwoInOneDayDate(twoInOneDayId, date, ignoreConflictWarning) {
    return apiClient.post(this.urlStart + `organisers/two-in-one-day/${twoInOneDayId}/store-selected-date`, {
      date,
      ignoreConflictWarning
    });
  },

  saveTrainerCourseSuitability(trainerCourseSuitability, abstractableId, abstractableType) {
    if (!Object.hasOwnProperty.call(trainerCourseSuitability, "id")) {
      let params = trainerCourseSuitability;
      params.abstractable_id = abstractableId;
      params.abstractable_type = abstractableType;
      return apiClient.post(this.urlStart + `trainer-course-suitability`, params);
    } else {
      return apiClient.patch(
        this.urlStart + `trainer-course-suitability/${trainerCourseSuitability.id}`,
        trainerCourseSuitability
      );
    }
  },

  saveTrainerGroupCompatibility(trainerGroupCompatibility, groupId) {
    if (!Object.hasOwnProperty.call(trainerGroupCompatibility, "id")) {
      let params = trainerGroupCompatibility;
      params.group_id = groupId;
      return apiClient.post(this.urlStart + `trainer-group-compatibility`, params);
    } else {
      return apiClient.patch(
        this.urlStart + `trainer-group-compatibility/${trainerGroupCompatibility.id}`,
        trainerGroupCompatibility
      );
    }
  },

  saveTrainerOpusScore(trainerData) {
    if (!Object.hasOwnProperty.call(trainerData, "id")) {
      let params = trainerData;
      return apiClient.post(this.urlStart + `trainer-data`, params);
    } else {
      return apiClient.patch(this.urlStart + `trainer-data/${trainerData.id}`, trainerData);
    }
  },

  saveProjectRequestTrainerExclusions(projectRequestId, excludedTrainerIds, thresholds) {
    return apiClient.post(this.urlStart + `bookings/project/request/${projectRequestId}/trainer-exclusions`, {
      excludedTrainerIds,
      thresholds
    });
  },

  canvasTrainersAvailability(projectRequestId) {
    return apiClient.post(this.urlStart + `bookings/project/request/${projectRequestId}/canvas-trainers-availability`);
  },

  saveTrainerJourney(params) {
    return apiClient.post(this.urlStart + `trainer-journey`, params);
  },

  askOrganiserToBook(projectRequestId) {
    return apiClient.post(this.urlStart + `bookings/project/request/${projectRequestId}/ask-organiser-to-book`);
  },

  askOrganiserToBookTwoInOneDay(twoInOneDayId) {
    return apiClient.post(this.urlStart + `bookings/project/two-in-one-day/${twoInOneDayId}/ask-organiser-to-book`);
  },

  remindOrganiserToBook(projectRequestId) {
    return apiClient.post(this.urlStart + `bookings/project/request/${projectRequestId}/remind-organiser-to-book`);
  },

  remindOrganiserToBookTwoInOneDay(twoInOneDayId) {
    return apiClient.post(this.urlStart + `bookings/project/two-in-one-day/${twoInOneDayId}/remind-organiser-to-book`);
  },

  tellOrganiserNothingIsAvailable(projectRequestId) {
    return apiClient.post(
      this.urlStart + `bookings/project/request/${projectRequestId}/tell-organiser-nothing-is-available`
    );
  },

  tellOrganiserNothingIsAvailableForTwoInOneDay(twoInOneDayId) {
    return apiClient.post(
      this.urlStart + `bookings/project/two-in-one-day/${twoInOneDayId}/tell-organiser-nothing-is-available`
    );
  },

  getGroupPledCourses(groupId) {
    return apiClient.get(this.urlStart + `group/${groupId}/pled-courses`);
  },

  getGroupsManaged() {
    return apiClient.get(this.urlStart + `groups-managed`);
  },

  getGroupSubgroups(groupId) {
    return apiClient.get(this.urlStart + `group/${groupId}/subgroups`);
  },

  checkForCommonUsersPreMerge(redundantGroupId, destinationGroupId) {
    return apiClient.get(this.urlStart + `group/${redundantGroupId}/${destinationGroupId}/common-users`);
  },

  mergeOrganisations(redundantGroupId, destinationGroupId, password, commonUsers, destinationSubgroupId) {
    return apiClient.post(this.urlStart + `group/${redundantGroupId}/${destinationGroupId}/merge`, {
      password,
      commonUsers,
      destinationSubgroupId
    });
  },

  //The venues that have already been used by this group, and that have not been excluded
  getGroupUsedVenues(groupId) {
    return apiClient.get(this.urlStart + `group/${groupId}/venues`);
  },

  getCoursesWithExtraData() {
    return apiClient.get(this.urlStart + `courses-with-extra-data`);
  },

  createVenue(params) {
    return apiClient.post(this.urlStart + `venues`, params);
  },

  updateVenue(params) {
    return apiClient.patch(this.urlStart + `venues/${params.id}`, params);
  },

  getVenue(venueId) {
    return apiClient.get(this.urlStart + `venues/${venueId}`);
  },

  getVenues() {
    return apiClient.get(this.urlStart + `venues`);
  },

  deleteVenue(venueId) {
    return apiClient.delete(this.urlStart + `venues/${venueId}`);
  },

  getVenueExclusions(venueId) {
    return apiClient.get(this.urlStart + `venues/${venueId}/exclusions`);
  },

  saveVenueExclusions(venueId, excludedGroupIds) {
    return apiClient.put(this.urlStart + `venues/${venueId}/exclusions`, { excludedGroupIds });
  },

  createLocation(params) {
    return apiClient.post(this.urlStart + `locations`, params);
  },

  updateLocation(params) {
    return apiClient.patch(this.urlStart + `locations/${params.id}`, params);
  },

  getLocation(locationId) {
    return apiClient.get(this.urlStart + `locations/${locationId}`);
  },

  deleteLocation(locationId) {
    return apiClient.delete(this.urlStart + `locations/${locationId}`);
  },

  getLocations() {
    return apiClient.get(this.urlStart + `locations`);
  },

  createDocument(params) {
    return apiClient.post(this.urlStart + `documents`, params);
  },

  updateDocument(documentId, params) {
    return apiClient.post(this.urlStart + `documents/${documentId}`, params);
  },

  deleteDocument(documentId) {
    return apiClient.delete(this.urlStart + `documents/${documentId}`);
  },

  getDocuments(groupId) {
    if (groupId) {
      return apiClient.get(this.urlStart + `documents/for-group/${groupId}`);
    } else {
      return apiClient.get(this.urlStart + `documents`);
    }
  },

  getDocument(documentId) {
    return apiClient.get(this.urlStart + `documents/${documentId}`);
  },

  getAuditTypes() {
    return apiClient.get(this.urlStart + `audit-types`);
  },

  getAudits(auditTypeId, page, perPage, filters) {
    let params = { perPage, filters };

    return apiClient.post(this.urlStart + `audits/${auditTypeId}?page=${page}`, params);
  },

  downloadAuditsCsv(auditTypeId, page, perPage, filters) {
    let params = { perPage, filters };

    return apiClient.post(this.urlStart + `audits/download-csv/${auditTypeId}`, params);
  },

  fetchPolyCourseImages(polyCourseId, polyCourseType) {
    return apiClient.get(this.urlStart + `poly-course-image/${polyCourseId}/${polyCourseType}`);
  },

  savePolyCourseImage(formData) {
    return apiClient.post(this.urlStart + `poly-course-image`, formData);
  },

  hideGroupAbstractCourseLicence(groupId, abstractCourseId, certificateMonths) {
    return apiClient.post(
      this.urlStart + `group/${groupId}/hide-abstract-course-licence/${abstractCourseId}/${certificateMonths}`
    );
  },

  unhideGroupAbstractCourseLicence(groupId, abstractCourseId, certificateMonths) {
    return apiClient.post(
      this.urlStart + `group/${groupId}/unhide-abstract-course-licence/${abstractCourseId}/${certificateMonths}`
    );
  },

  getCertMonthCandidates(groupId) {
    return apiClient.get(this.urlStart + `group/${groupId}/cert-month-candidates`);
  },

  updateCertMonths(groupId, polyCourseId, polyCourseType, newMonths) {
    return apiClient.patch(this.urlStart + `group/${groupId}/cert-months`, {
      newMonths,
      polyCourseId,
      polyCourseType
    });
  },

  getGroupNotes(groupId) {
    return apiClient.get(this.urlStart + `group-notes/${groupId}`);
  },

  saveGroupLogo(groupId, formData) {
    return apiClient.post(this.urlStart + `group/${groupId}/logo`, formData);
  },

  getManagerStats(groupId) {
    return apiClient.get(this.urlStart + `group-dashboard/manager-stats/${groupId}`);
  },

  getCourseStats(groupId) {
    return apiClient.get(this.urlStart + `group-dashboard/course-stats/${groupId}`);
  },

  getPledStats(groupId) {
    return apiClient.get(this.urlStart + `group-dashboard/pled-stats/${groupId}`);
  },

  getTrainerPledStats() {
    return apiClient.get(this.urlStart + `pled-stats`);
  },

  getRecentEvents(groupId) {
    return apiClient.get(this.urlStart + `group-dashboard/recent-events/${groupId}`);
  },

  getUserStats(groupId) {
    return apiClient.get(this.urlStart + `group-dashboard/user-stats/${groupId}`);
  },

  storeGroupNote(note) {
    return apiClient.post(this.urlStart + `group-notes/${note.groupId}`, note);
  },

  updateGroupNote(note) {
    return apiClient.patch(this.urlStart + `group-notes/${note.id}`, note);
  },

  deleteGroupNote(note) {
    return apiClient.delete(this.urlStart + `group-notes/${note.id}`);
  },

  overrideScoUser(scoUserId, newData) {
    return apiClient.post(this.urlStart + `sco-user/${scoUserId}/override`, newData);
  },

  getScoUserOverrides(scoUserId) {
    return apiClient.get(this.urlStart + `sco-user/${scoUserId}/overrides`);
  },

  getScoUserAudit(scoUserId) {
    return apiClient.get(this.urlStart + `sco-user/${scoUserId}/audit`);
  },

  getDeveloperSetting(type) {
    return apiClient.get(this.urlStart + `developer-setting/${type}`);
  },

  setDeveloperSetting(params) {
    return apiClient.put(this.urlStart + `developer-setting`, params);
  },

  getCourseOverviewData() {
    return apiClient.get(this.urlStart + `reports/course-overview`);
  },

  getAverageCourseFeedback() {
    return apiClient.get(this.urlStart + `reports/average-course-feedback`);
  },

  getAverageTrainerFeedback() {
    return apiClient.get(this.urlStart + `reports/average-trainer-feedback`);
  },

  sendCourseReminders(abstractCourseId, userIds) {
    return apiClient.post(this.urlStart + `abstract-course/${abstractCourseId}/reminders`, {
      userIds
    });
  },

  addExtraLicencesToVirtualGroup(virtualGroupId, quantity) {
    return apiClient.patch(this.urlStart + `virtual-group/${virtualGroupId}/extra-licences`, {
      quantity
    });
  },

  extraDaysVirtualGroupUser(virtualGroupId, userId, extraDays) {
    return apiClient.post(this.urlStart + `virtual-group/${virtualGroupId}/individual-extra-days`, {
      userId,
      extraDays
    });
  },

  sendAssessmentDueReminderToVirtualGroup(virtualGroupId, userIds) {
    return apiClient.post(this.urlStart + `virtual-group/${virtualGroupId}/manual-assessment-due-reminder`, {
      userIds
    });
  },

  getSubgroupPreArchivingMessageData(subgroupId) {
    return apiClient.get(this.urlStart + `subgroup/${subgroupId}/pre-archive-message-data`);
  },

  archiveGroup(groupId) {
    return apiClient.patch(this.urlStart + `group/${groupId}/archive`);
  },

  unarchiveGroup(groupId) {
    return apiClient.patch(this.urlStart + `group/${groupId}/unarchive`);
  },

  unarchiveSubgroup(subgroupId) {
    return apiClient.patch(this.urlStart + `subgroup/${subgroupId}/unarchive`);
  },

  archiveSubgroup(subgroupId) {
    return apiClient.patch(this.urlStart + `subgroup/${subgroupId}/archive`);
  },

  getGroupMembers(groupId) {
    return apiClient.get(this.urlStart + `group/${groupId}/members`);
  },

  getSubgroupMembers(subgroupId) {
    return apiClient.get(this.urlStart + `subgroup/${subgroupId}/members`);
  },

  resetPasswords(selectedUserIds, groupId, subgroupId) {
    if (subgroupId) {
      return apiClient.post(this.urlStart + `subgroup/${subgroupId}/password-resets`, {
        selectedUserIds
      });
    } else {
      return apiClient.post(this.urlStart + `group/${groupId}/password-resets`, {
        selectedUserIds
      });
    }
  },

  getUsersDoingCoursesStats() {
    return apiClient.get(this.urlStart + `users-doing-courses`);
  },

  acceptTandcs() {
    return apiClient.post(this.urlStart + `accept-tandcs`);
  },

  getSuperAdmins() {
    return apiClient.get(this.urlStart + `super-admins`);
  },

  removeSuperAdminRole(superAdminUserId) {
    return apiClient.patch(this.urlStart + `super-admins/${superAdminUserId}/remove-role`);
  },

  saveSuperAdmin(superAdmin) {
    return apiClient.post(this.urlStart + `super-admins/new`, superAdmin);
  },

  confirmSuperAdmin(tokenId, tokenKey) {
    return apiClient.post(this.urlStart + `super-admins/confirm/${tokenId}/${tokenKey}`);
  },

  getAssessmentsDue() {
    return apiClient.get(this.urlStart + `reports/assessments-due`);
  },

  getBrokenManagers() {
    return apiClient.get(this.urlStart + `reports/broken-managers`);
  },

  storeBrokenManagersFix(params) {
    return apiClient.post(this.urlStart + `reports/broken-managers`, params);
  },

  deleteBrokenManagersFix(userMergeTargetId) {
    return apiClient.delete(this.urlStart + `reports/broken-managers/${userMergeTargetId}`);
  },

  applyBrokenManagersFixes() {
    return apiClient.post(this.urlStart + `reports/broken-managers/apply-fixes`);
  },

  mergeUsers(params) {
    return apiClient.post(this.urlStart + `users/merge`, params);
  },

  restoreArchivedUser(userId) {
    return apiClient.post(this.urlStart + `users/restore-archived-user/`, {
      userId
    });
  },

  groupDuplicationOverview() {
    return apiClient.get(this.urlStart + `reports/group/duplication-overview`);
  },

  isGroupLooselyAssociated(virtualGroupId) {
    return apiClient.get(this.urlStart + `virtual-group/${virtualGroupId}/is-group-loosely-associated`);
  },

  getUpcomingVirtualGroups() {
    return apiClient.get(this.urlStart + `trainer/virtual-groups`);
  },

  getTrainersVirtualGroups(userId) {
    return apiClient.get(this.urlStart + `trainer/${userId}/virtual-groups`);
  },

  getTrainersCompletedVirtualGroups(userId) {
    return apiClient.get(this.urlStart + `trainer/${userId}/completed-virtual-groups`);
  },

  getCertificateExpiryNotificationSetting() {
    return apiClient.get(this.urlStart + `user/certificate-expiry-notification`);
  },

  updateCertificateExpiryNotificationSetting(params) {
    return apiClient.patch(this.urlStart + `user/certificate-expiry-notification`, params);
  },

  generateVirtualGroupPdfs(virtualGroupId) {
    return apiClient.get(this.urlStart + `virtual-group/${virtualGroupId}/certificate-pdfs`);
  },

  checkBatchPdfStatus(virtualGroupId, batchId) {
    return apiClient.get(this.urlStart + `virtual-group/${virtualGroupId}/certificate-pdfs/${batchId}/progress`);
  },

  downloadBatchPdfZip(virtualGroupId, batchId) {
    return apiClient.get(this.urlStart + `virtual-group/${virtualGroupId}/certificate-pdfs/${batchId}/download`, {
      responseType: "blob"
    });
  },

  deleteVirtualGroupPdfs(virtualGroupId) {
    return apiClient.post(this.urlStart + `virtual-group/${virtualGroupId}/delete-certificate-pdfs`);
  },

  getVirtualGroupHealth() {
    return apiClient.get(this.urlStart + `virtual-groups/health`);
  },

  getGroupVirtualGroupHealth() {
    return apiClient.get(this.urlStart + `group/virtual-groups/health`);
  },

  getLicenceLevels(level) {
    return apiClient.get(this.urlStart + `reports/licence-levels/${level}`);
  },

  getAllLicenceLevels(level) {
    return apiClient.get(this.urlStart + `reports/all-licence-levels/${level}`);
  },

  getLicenceLevelsLowGroupCount() {
    return apiClient.get(this.urlStart + `reports/licence-levels-low-group-count`);
  },

  getLicenceLevelSettings(level) {
    return apiClient.get(this.urlStart + `reports/licence-level-settings/${level}`);
  },

  saveLicenceLevelSettings(level, params) {
    return apiClient.post(this.urlStart + `reports/licence-level-settings/${level}`, params);
  },

  removeLicenceLevelSettings(level, params) {
    return apiClient.post(this.urlStart + `reports/licence-level-settings/${level}/remove`, params);
  },

  saveOpusDefaultLicenceLevelSetting(params) {
    return apiClient.post(this.urlStart + `reports/opus-default-licence-level-setting/`, params);
  },

  saveOpusGroupLicenceLevelSetting(params) {
    return apiClient.post(this.urlStart + `reports/opus-group-licence-level-settings/`, params);
  },

  getExpiredCertificatesGroupCount() {
    return apiClient.get(this.urlStart + `reports/recently-expired-certificates-group-count`);
  },

  getExpiredCertificatesOrgAdminSummary() {
    return apiClient.get(this.urlStart + `reports/org-admin-certificate-summary`);
  },

  getRecentlyExpiredCertificateCounts(level) {
    return apiClient.get(this.urlStart + `reports/recently-expired-certificates-counts/${level}`);
  },

  getExpiringSoonCertificateCounts(level) {
    return apiClient.get(this.urlStart + `reports/expiring-soon-certificates-counts/${level}`);
  },

  getRecentExpiredCertificateDetailsForGroupAndCourse(groupId, abstractCourseId, level, courseType) {
    switch (courseType) {
      case "online":
        return apiClient.get(
          this.urlStart +
            `reports/recently-expired-certificates/${groupId}/` +
            `abstract-course/${abstractCourseId}/details/${level}`
        );
      case "paper":
        return apiClient.get(
          this.urlStart +
            `reports/recently-expired-certificates/${groupId}/` +
            `abstract-paper-course/${abstractCourseId}/details/${level}`
        );
    }
  },

  getExpiringSoonCertificateDetailsForGroupAndCourse(groupId, abstractCourseId, level, courseType) {
    switch (courseType) {
      case "online":
        return apiClient.get(
          this.urlStart +
            `reports/expiring-soon-certificates/${groupId}/` +
            `abstract-course/${abstractCourseId}/details/${level}`
        );
      case "paper":
        return apiClient.get(
          this.urlStart +
            `reports/expiring-soon-certificates/${groupId}/` +
            `abstract-paper-course/${abstractCourseId}/details/${level}`
        );
    }
  },

  getAllExpiredCertificateCounts(level) {
    return apiClient.get(this.urlStart + `reports/all-expired-certificates-counts/${level}`);
  },

  getAllExpiredCertificateDetailsForGroupAndCourse(groupId, abstractCourseId, level, courseType) {
    switch (courseType) {
      case "online":
        return apiClient.get(
          this.urlStart +
            `reports/all-expired-certificates/${groupId}/` +
            `abstract-course/${abstractCourseId}/details/${level}`
        );
      case "paper":
        return apiClient.get(
          this.urlStart +
            `reports/all-expired-certificates/${groupId}/` +
            `abstract-paper-course/${abstractCourseId}/details/${level}`
        );
    }
  },

  getGroups() {
    return apiClient.get(this.urlStart + `groups`);
  },

  getGroupsHavingPleds() {
    return apiClient.get(this.urlStart + `groups?havingPleds=true`);
  },

  getArchivedGroups() {
    return apiClient.get(this.urlStart + `archived-groups`);
  },

  getGroupsWithLicencedAbstractCourseIds() {
    return apiClient.get(this.urlStart + `groups/with-licenced-abstract-course-ids`);
  },

  getGroupsWithStandaloneExternalCourses() {
    return apiClient.get(this.urlStart + `groups/with-standalone-external-courses`);
  },

  getGroupTrainingRecord(groupId) {
    return apiClient.get(this.urlStart + `reports/${groupId}/training-record`);
  },

  getCourse(courseId) {
    return apiClient.get(this.urlStart + `course/${courseId}`);
  },

  getAbstractCourse(abstractCourseId) {
    return apiClient.get(this.urlStart + `abstract-course/${abstractCourseId}`);
  },

  getCourses() {
    return apiClient.get(this.urlStart + `courses`);
  },

  getSectors() {
    return apiClient.get(this.urlStart + `sectors`);
  },

  getLevels() {
    return apiClient.get(this.urlStart + `levels`);
  },

  getCategories() {
    return apiClient.get(this.urlStart + `categories`);
  },

  getAbstractCourses() {
    return apiClient.get(this.urlStart + `abstract-courses`);
  },

  getPolyAbstractCourseOverrides(groupId) {
    return apiClient.get(this.urlStart + `poly-abstract-courses/${groupId}/overrides`);
  },

  postPolyAbstractCourseOverrides(groupId, overrides) {
    return apiClient.post(this.urlStart + `poly-abstract-courses/${groupId}/overrides`, {
      overrides
    });
  },

  removePolyAbstractCourseCertificateMonthsOverride(groupId, polyAbstractCourseId, type) {
    return apiClient.patch(this.urlStart + `poly-abstract-courses/${groupId}/remove-certificate-month-override`, {
      polyAbstractCourseId,
      type
    });
  },

  getDlAndElAbstractCourses() {
    return apiClient.get(this.urlStart + `dl-and-el-abstract-courses`);
  },

  getCountries() {
    return apiClient.get(this.urlStart + `countries`);
  },

  getAddresses(groupId) {
    return apiClient.get(this.urlStart + `group-addresses/${groupId}`);
  },

  deleteAddress(addressId) {
    return apiClient.delete(this.urlStart + `group-address/${addressId}`);
  },

  getOwnedAbstractCourseIds(groupingLevel, groupingId) {
    return apiClient.get(this.urlStart + `owned-dl-and-el-abstract-courses/${groupingLevel}/${groupingId}`);
  },

  submitLicenceRequestToOrg(subgroupId, params) {
    return apiClient.post(this.urlStart + `subgroup-request-for-licences/${subgroupId}`, params);
  },

  createAddress(groupId, address) {
    return apiClient.post(this.urlStart + `group-address-for-licences/${groupId}`, address);
  },

  submitLicenceOrderToOpus(groupId, params) {
    return apiClient.post(this.urlStart + `group-order-for-licences/${groupId}`, params);
  },

  submitSubgroupLicenceOrderToOpus(groupId, subgroupId, params) {
    return apiClient.post(this.urlStart + `subgroup-order-for-licences/${groupId}/${subgroupId}`, params);
  },

  getOrgsLicenceRequestsFromServices() {
    return apiClient.get(this.urlStart + `reports/subgroup-requests-for-licences`);
  },

  getOpusLicenceRequestsFromOrgs() {
    return apiClient.get(this.urlStart + `reports/group-requests-for-licences`);
  },

  fulfillLicenceRequestId(requestId) {
    return apiClient.post(this.urlStart + `fulfill-service-licence-request/${requestId}`);
  },

  deleteLicenceRequestId(requestId) {
    return apiClient.delete(this.urlStart + `delete-service-licence-request/${requestId}`);
  },

  deleteGroupLicenceRequestId(requestId) {
    return apiClient.delete(this.urlStart + `delete-group-licence-request/${requestId}`);
  },

  getAbstractCoursesWithLicencedGroupIds() {
    return apiClient.get(this.urlStart + `abstract-courses/with-licenced-group-ids`);
  },

  archiveAbstractCourse(abstractCourseId) {
    return apiClient.patch(this.urlStart + `abstract-course/${abstractCourseId}/archive`);
  },

  archiveAbstractPaperCourse(abstractPaperCourseId) {
    return apiClient.patch(this.urlStart + `abstract-paper-course/${abstractPaperCourseId}/archive`);
  },

  getTrainerCourses() {
    return apiClient.get(this.urlStart + `trainer-courses`);
  },

  getTrainerCoursesForGroup(groupId) {
    return apiClient.get(this.urlStart + `trainer-courses-for-group/${groupId}`);
  },

  getTrainerCoursesMinimal() {
    return apiClient.get(this.urlStart + `trainer-courses-minimal`);
  },

  getTrainerCoursesMinimalForGroup(groupId) {
    return apiClient.get(this.urlStart + `trainer-courses-minimal-for-group/${groupId}`);
  },

  getNonTrainerCourses() {
    return apiClient.get(this.urlStart + `non-trainer-courses`);
  },

  getNonTrainerAbstractCourses() {
    return apiClient.get(this.urlStart + `non-trainer-abstract-courses`);
  },

  getNonTrainerAbstractCoursesPurchasedByGroup(groupId) {
    return apiClient.get(this.urlStart + `non-trainer-abstract-courses-purchased-by-group/${groupId}`);
  },

  getPaperCourse(paperCourseId) {
    return apiClient.get(this.urlStart + `paper-course/${paperCourseId}`);
  },

  getAbstractPaperCourse(abstractPaperCourseId) {
    return apiClient.get(this.urlStart + `abstract-paper-course/${abstractPaperCourseId}`);
  },

  getPaperCourses() {
    return apiClient.get(this.urlStart + `paper-courses`);
  },

  getPaperCoursesForGroup(groupId) {
    return apiClient.get(this.urlStart + `paper-courses-for-group/${groupId}`);
  },

  getPaperCoursesMinimal() {
    return apiClient.get(this.urlStart + `paper-courses-minimal`);
  },

  getPaperCoursesMinimalForGroup($groupId) {
    return apiClient.get(this.urlStart + `paper-courses-minimal-for-group/${groupId}`);
  },

  getPolyRefreshers() {
    return apiClient.get(this.urlStart + `poly-refreshers`);
  },

  getAbstractPaperCourses() {
    return apiClient.get(this.urlStart + `abstract-paper-courses`);
  },

  getAbstractPaperCoursesWithLicencedGroupIds() {
    return apiClient.get(this.urlStart + `abstract-paper-courses/with-licenced-group-ids`);
  },

  getExternalCoursesWithGroupIds() {
    return apiClient.get(this.urlStart + `external-courses/with-group-ids`);
  },

  getPledAbsenteeStats(params) {
    return apiClient.post(this.urlStart + `reports/pled-absentee-stats/${params.group_id}`, params);
  },

  getAbstractCourseResults(groupId, abstractCourseId, adminLevel) {
    return apiClient.get(this.urlStart + `abstract-course-results/${groupId}/${abstractCourseId}/${adminLevel}`);
  },

  getAbstractPaperCourseResults(groupId, abstractPaperCourseId, adminLevel) {
    return apiClient.get(
      this.urlStart + `abstract-paper-course-results/${groupId}/${abstractPaperCourseId}/${adminLevel}`
    );
  },

  getExternalCourseResults(groupId, externalCourseId, adminLevel) {
    return apiClient.get(this.urlStart + `external-course-results/${groupId}/${externalCourseId}/${adminLevel}`);
  },

  postPaperCourse(params) {
    return apiClient.post(this.urlStart + `paper-course`, params);
  },

  updatePaperCourse(paperCourseId, params) {
    return apiClient.post(this.urlStart + `paper-course/${paperCourseId}`, params);
  },

  getGroup(groupId) {
    return apiClient.get(this.urlStart + `group/${groupId}`);
  },

  getGroupSubgroupVgCounts(groupId) {
    return apiClient.get(this.urlStart + `group/${groupId}/subgroup-vg-counts`);
  },

  getGroupCourseCertificateSettings(groupId, abstractCourseId) {
    return apiClient.get(this.urlStart + `group/${groupId}/abstract-course/${abstractCourseId}/certificate-settings`);
  },

  getGroupPaperCourseCertificateSettings(groupId, abstractPaperCourseId) {
    return apiClient.get(
      this.urlStart + `group/${groupId}/abstract-paper-course/${abstractPaperCourseId}/certificate-settings`
    );
  },

  postGroupCourseCertificateSettings(groupId, abstractCourseId, groupSettings) {
    return apiClient.post(
      this.urlStart + `group/${groupId}/abstract-course/${abstractCourseId}/certificate-settings`,
      groupSettings
    );
  },

  postGroupPaperCourseCertificateSettings(groupId, abstractPaperCourseId, groupSettings) {
    return apiClient.post(
      this.urlStart + `group/${groupId}/abstract-paper-course/${abstractPaperCourseId}/certificate-settings`,
      groupSettings
    );
  },

  uploadUsersCsv(groupId, formData) {
    return apiClient.post(this.urlStart + `group/${groupId}/users-csv-upload`, formData);
  },

  uploadUserEditsCsv(groupId, formData) {
    return apiClient.post(this.urlStart + `group/${groupId}/user-edits-csv-upload`, formData);
  },

  getGroupsUsers(groupId) {
    return apiClient.get(this.urlStart + `group/${groupId}/users`);
  },

  getGroupsArchivedUsers(groupId) {
    return apiClient.get(this.urlStart + `group/${groupId}/archived-users`);
  },

  archiveGroupUser(groupId, userId) {
    return apiClient.patch(this.urlStart + `group/${groupId}/user/${userId}/archive`);
  },

  restoreGroupUser(groupId, userId) {
    return apiClient.patch(this.urlStart + `group/${groupId}/user/${userId}/restore`);
  },

  makeManager(groupId, userId) {
    return apiClient.post(this.urlStart + `group/${groupId}/manager/${userId}`);
  },

  makeSubgroupManager(subgroupId, userId) {
    return apiClient.post(this.urlStart + `subgroup/${subgroupId}/manager/${userId}`);
  },

  getServiceMemberCount(subgroupId) {
    return apiClient.get(this.urlStart + `subgroup/${subgroupId}/member-count`);
  },

  isSubgroupManagerWithAccessToGeneralPot(subgroupId) {
    return apiClient.get(this.urlStart + `subgroup/${subgroupId}/is-subgroup-manager-with-access-to-general-pot`);
  },

  makeVirtualGroupManager(virtualGroupId, userId) {
    return apiClient.post(this.urlStart + `virtual-group/${virtualGroupId}/manager/${userId}`);
  },

  removeManager(groupId, userId) {
    return apiClient.post(this.urlStart + `group/${groupId}/remove-manager/${userId}`);
  },

  removeSubgroupManager(subgroupId, userId) {
    return apiClient.post(this.urlStart + `subgroup/${subgroupId}/remove-manager/${userId}`);
  },

  removeVirtualGroupManager(virtualGroupId, userId) {
    return apiClient.post(this.urlStart + `virtual-group/${virtualGroupId}/remove-manager/${userId}`);
  },

  grantAccessToGeneralPot(subgroupId, userId) {
    return apiClient.post(this.urlStart + `subgroup/${subgroupId}/grant-access-to-general-pot/${userId}`);
  },

  removeAccessToGeneralPot(subgroupId, userId) {
    return apiClient.post(this.urlStart + `subgroup/${subgroupId}/remove-access-to-general-pot/${userId}`);
  },

  grantViewSiblings(subgroupId, userId) {
    return apiClient.post(this.urlStart + `subgroup/${subgroupId}/grant-view-siblings/${userId}`);
  },

  removeViewSiblings(subgroupId, userId) {
    return apiClient.post(this.urlStart + `subgroup/${subgroupId}/remove-view-siblings/${userId}`);
  },

  getSubgroup(subgroupId) {
    return apiClient.get(this.urlStart + `subgroup/${subgroupId}`);
  },

  getSubgroupsUsers(subgroupId) {
    return apiClient.get(this.urlStart + `subgroup/${subgroupId}/users`);
  },

  reclaimAllSubgroupLicences(subgroupId) {
    return apiClient.post(this.urlStart + `subgroup-licences/${subgroupId}/reclaim-all-licences`);
  },

  getSubgroupsLicencePurchasesAndPools(subgroupId) {
    return apiClient.get(this.urlStart + `subgroup-licences/${subgroupId}`);
  },

  getVirtualGroup(virtualGroupId) {
    return apiClient.get(this.urlStart + `virtual-group/${virtualGroupId}`);
  },

  revokeSingleVirtualGroupLicence(virtualGroupId, userId) {
    return apiClient.post(this.urlStart + `virtual-group/${virtualGroupId}/revoke-licence`, {
      userId
    });
  },

  revokeSingleVirtualGroupPaperCourseLicence(virtualGroupId, userId) {
    return apiClient.post(this.urlStart + `virtual-group/${virtualGroupId}/revoke-paper-course-licence`, {
      userId
    });
  },

  storeUninvitedAttendees(virtualGroupId, uninvitedAttendees) {
    return apiClient.post(this.urlStart + `virtual-group/${virtualGroupId}/uninvited-attendees`, {
      uninvitedAttendees
    });
  },

  bulkIssueVirtualGroupLicence(virtualGroupId, userStatuses) {
    return apiClient.post(this.urlStart + `virtual-group/${virtualGroupId}/bulk-issue-licences`, {
      userStatuses
    });
  },

  bulkIssueVirtualGroupPaperLicence(virtualGroupId, userStatuses) {
    return apiClient.post(this.urlStart + `virtual-group/${virtualGroupId}/bulk-issue-paper-licences`, {
      userStatuses
    });
  },

  issueSingleVirtualGroupLicence(virtualGroupId, userId, status) {
    let params = {
      userId
    };
    if (status) {
      params.status = status;
    }
    return apiClient.post(this.urlStart + `virtual-group/${virtualGroupId}/issue-licence`, params);
  },

  issueSingleVirtualGroupPaperCourseLicence(virtualGroupId, userId) {
    return apiClient.post(this.urlStart + `virtual-group/${virtualGroupId}/issue-paper-course-licence/completed`, {
      userId
    });
  },

  issueSingleVirtualGroupPaperCourseLicenceSetStatus(virtualGroupId, userId, status) {
    return apiClient.post(this.urlStart + `virtual-group/${virtualGroupId}/issue-paper-course-licence/status`, {
      userId,
      status
    });
  },

  createNewVirtualGroupAttendees(virtualGroupId, users) {
    return apiClient.post(this.urlStart + `virtual-group/${virtualGroupId}/create-new-members`, {
      users
    });
  },

  getAllTrainers() {
    return apiClient.get(this.urlStart + `trainers/all`);
  },

  getTrainers() {
    return apiClient.get(this.urlStart + `trainers`);
  },

  activateTrainer(trainerId) {
    return apiClient.patch(this.urlStart + `trainer/${trainerId}/activate`);
  },

  deactivateTrainer(trainerId) {
    return apiClient.patch(this.urlStart + `trainer/${trainerId}/deactivate`);
  },

  getGroupSpecificVenues(groupId) {
    return apiClient.get(this.urlStart + `venues/group/${groupId}`);
  },

  getGroupOrganisers(groupId) {
    return apiClient.get(this.urlStart + `group/${groupId}/organisers`);
  },

  getZoomAccounts() {
    return apiClient.get(this.urlStart + `zoom-accounts`);
  },

  newTrainer(params) {
    return apiClient.post(this.urlStart + `trainer`, params);
  },

  updateTrainer(formData) {
    return apiClient.post(this.urlStart + `trainer/${formData.get("id")}`, formData);
  },

  updateTrainerLogin(formData) {
    return apiClient.post(this.urlStart + `trainer/login/${formData.get("id")}`, formData);
  },

  getTrainer(id) {
    return apiClient.get(this.urlStart + `trainer/${id}`);
  },

  saveVirtualGroupTrainer(virtualGroupId, trainerId) {
    return apiClient.patch(this.urlStart + `virtual-group/${virtualGroupId}/trainer/${trainerId}`);
  },

  postUserWithEmail(params) {
    return apiClient.post(this.urlStart + `user/with-email`, params);
  },

  postUserWithUsername(params) {
    return apiClient.post(this.urlStart + `user/with-username`, params);
  },

  updateUserTrainerRoleStatus(userId, params) {
    return apiClient.post(this.urlStart + `user/${userId}/trainer-status`, params);
  },

  getUsername(firstName, lastName) {
    return apiClient.post(this.urlStart + `username`, {
      firstName,
      lastName
    });
  },

  getGroupsLicencePurchases(groupId) {
    return apiClient.get(this.urlStart + `group/${groupId}/licence-purchases`);
  },

  getGroupsNonTrainerLicencePurchases(groupId) {
    return apiClient.get(this.urlStart + `group/${groupId}/licence-purchases-non-trainer`);
  },

  getGroupsHiddenNonTrainerLicencePurchases(groupId) {
    return apiClient.get(this.urlStart + `group/${groupId}/hidden-licence-purchases-non-trainer`);
  },

  getMembersWithoutAnActiveLicence(groupId, abstractCourseId) {
    return apiClient.get(this.urlStart + `group/${groupId}/members-without-an-active-licence/${abstractCourseId}`);
  },

  getSubgroupMembersWithoutAnActiveLicence(subgroupId, abstractCourseId) {
    return apiClient.get(
      this.urlStart + `subgroup/${subgroupId}/members-without-an-active-licence/${abstractCourseId}`
    );
  },

  getUserWithLicences(userId) {
    return apiClient.get(this.urlStart + `user-licences/${userId}`);
  },

  reclaimLicence(courseUser) {
    return apiClient.post(this.urlStart + `reclaim-user-licence/${courseUser.id}`);
  },

  deleteLicence(courseUser) {
    return apiClient.delete(this.urlStart + `delete-user-licence/${courseUser.id}`);
  },

  freeRetry(scoUser) {
    return apiClient.post(this.urlStart + `free-retry/${scoUser.id}`);
  },

  allocateGeneralPotLicence(groupId, userId, abstractCourseId, certificateMonths) {
    return apiClient.post(
      this.urlStart + `group-licences/${groupId}/user/${userId}/abstract-course/${abstractCourseId}`,
      {
        certificateMonths
      }
    );
  },

  postPoolAllocation(groupId, abstractCourseId, quantity, subgroupId, virtualGroupId, certificateMonths, ref = null) {
    let params = {};
    params.quantity = parseInt(quantity, 10);
    params.certificateMonths = parseInt(certificateMonths, 10);
    params.ref = ref;
    if (subgroupId) {
      params.subgroupId = subgroupId;
    } else {
      params.virtualGroupId = virtualGroupId;
    }

    return apiClient.post(
      this.urlStart + `group-licences/${groupId}/abstract-course/${abstractCourseId}/pool-allocation`,
      params
    );
  },

  adjustLicencePurchaseQuantity(licencePurchaseId, params) {
    return apiClient.post(this.urlStart + `licence-purchase/${licencePurchaseId}/adjust-quantity`, params);
  },

  deleteLicencePurchase(licencePurchaseId) {
    return apiClient.delete(this.urlStart + `licence-purchase/${licencePurchaseId}`);
  },

  getGroupsLicencePurchasesOfCourse(groupId, abstractCourseId, certificateMonths) {
    return apiClient.get(
      this.urlStart +
        `group/${groupId}/licence-purchases/abstract-course/${abstractCourseId}/` +
        `certificate-months/${certificateMonths}`
    );
  },

  getGroupsLicencePurchasesOfPaperCourse(groupId, abstractPaperCourseId, certificateMonths) {
    return apiClient.get(
      this.urlStart +
        `group/${groupId}/licence-purchases/abstract-paper-course/${abstractPaperCourseId}/` +
        `certificate-months/${certificateMonths}`
    );
  },

  getMembersWithoutCourseLicence(groupId, courseId) {
    return apiClient.get(this.urlStart + `group/${groupId}/members-without-licence/course/${courseId}`);
  },

  getMembersWithoutPaperCourseLicence(groupId, paperCourseId) {
    return apiClient.get(this.urlStart + `group/${groupId}/members-without-licence/paper-course/${paperCourseId}`);
  },

  postSubgroup(grouping) {
    return apiClient.post(this.urlStart + `grouping`, grouping);
  },

  postVirtualGroup(grouping) {
    return apiClient.post(this.urlStart + `grouping`, grouping);
  },

  getVirtualGroupLicenceQuantity(virtualGroupId) {
    return apiClient.get(this.urlStart + `virtual-group/${virtualGroupId}/licence-quantity`);
  },

  getVirtualGroupDelegatePlacesAllowed(virtualGroupId) {
    return apiClient.get(this.urlStart + `virtual-group/${virtualGroupId}/delegate-places-allowed`);
  },

  groupsMembersWithSubgroupMembershipStatus(groupId, subgroupId) {
    return apiClient.get(this.urlStart + `group/${groupId}/subgroup/${subgroupId}/membership-status`);
  },

  postMembershipStatus(subgroupId, users) {
    return apiClient.post(this.urlStart + `subgroup-membership/${subgroupId}`, {
      users
    });
  },

  uploadSco(formData, callback) {
    return apiClient.post(this.urlStart + `course/sco-upload`, formData, {
      timeout: 10 * 60 * 1000,
      headers: {
        "Content-Type": "multipart/form-data"
      },
      onUploadProgress: (progressEvent) => {
        callback(progressEvent);
      }
    });
  },

  cancelCourseCreation(key) {
    return apiClient.post(this.urlStart + `course/cancel-sco-upload`, {
      key
    });
  },

  cancelAdditionalScoCreation(key, courseId) {
    return apiClient.post(this.urlStart + `course/cancel-additional-sco-upload`, {
      key,
      courseId
    });
  },

  confirmAdditionalScoCreation(courseId, formData) {
    return apiClient.post(this.urlStart + `course/complete-additional-sco-upload/${courseId}`, formData);
  },

  completeCourseCreationFirstEdition(formData) {
    return apiClient.post(this.urlStart + `course/complete-sco-upload-first-edition`, formData);
  },

  completeCourseCreationNewVersion(formData) {
    return apiClient.post(this.urlStart + `course/complete-sco-upload-new-version`, formData);
  },

  paperCourseCreationFirstEdition(formData) {
    return apiClient.post(this.urlStart + `abstract-paper-course/first-edition`, formData);
  },

  paperCourseCreationNewVersion(existingAbstractPaperCourseId) {
    return apiClient.post(this.urlStart + `abstract-paper-course/new-version/${existingAbstractPaperCourseId}`);
  },

  externalCourseCreation(formData) {
    return apiClient.post(this.urlStart + `external-course`, formData);
  },

  externalCourseUpdate(externalCourseId, formData) {
    return apiClient.post(this.urlStart + `external-course/${externalCourseId}`, formData);
  },

  getExternalCourses() {
    return apiClient.get(this.urlStart + `external-course`);
  },

  archiveExternalCourse(externalCourseId) {
    return apiClient.post(this.urlStart + `external-course/${externalCourseId}/archive`);
  },

  getExternalCourse(externalCourseId) {
    return apiClient.get(this.urlStart + `external-course/${externalCourseId}`);
  },

  saveExternalCourseAllocation(params) {
    return apiClient.post(this.urlStart + `external-course/allocation`, params);
  },

  updateExternalCourseUser(externalCourseUserId, params) {
    return apiClient.put(this.urlStart + `external-course-user/${externalCourseUserId}`, params);
  },

  getExternalCourseUser(externalCourseUserId) {
    return apiClient.get(this.urlStart + `external-course-user/${externalCourseUserId}`);
  },

  deleteExternalCourseUser(externalCourseUserId) {
    return apiClient.delete(this.urlStart + `external-course-user/${externalCourseUserId}`);
  },

  hasScoBeenUsed(scoId) {
    return apiClient.get(this.urlStart + `course/has-sco-been-used/${scoId}`);
  },

  uploadReplacementSco(scoToReplaceId, formData) {
    return apiClient.post(this.urlStart + `course/replace-sco/${scoToReplaceId}`, formData);
  },

  getCourseScos(courseId) {
    return apiClient.get(this.urlStart + `course/${courseId}/scos`);
  },

  getSco(scoId) {
    return apiClient.get(this.urlStart + `sco/${scoId}`);
  },

  patchSco(scoId, params) {
    return apiClient.patch(this.urlStart + `sco/${scoId}`, params);
  },

  updateScosSequence(courseId, scos) {
    return apiClient.post(this.urlStart + `course/${courseId}/scos-sequence`, { scos });
  },

  updateCourse(course) {
    return apiClient.patch(this.urlStart + `course/${course.id}`, course);
  },

  uploadAdditionalSco(course, formData, callback) {
    return apiClient.post(this.urlStart + `course/add-sco/${course.id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      onUploadProgress: (progressEvent) => {
        callback(progressEvent);
      }
    });
  },

  updateScoThumbnail(scoId, formData) {
    return apiClient.post(this.urlStart + `sco/update-thumbnail/${scoId}`, formData);
  },

  getUsers(page = 1, perPage = 20, filters = {}) {
    return apiClient.post(this.urlStart + `users?page=${page}`, {
      per_page: perPage,
      filters
    });
  },

  isEmailAvailableOnLiveSite(email) {
    return apiClient.post(this.urlStart + `demo-users/email-available-on-live-site`, { email });
  },

  isUsernameAvailableOnLiveSite(username) {
    return apiClient.post(this.urlStart + `demo-users/username-available-on-live-site`, { username });
  },

  deleteDemoUser(userId) {
    return apiClient.delete(this.urlStart + `demo-users/${userId}`);
  },

  makeDemoUserLive(userId) {
    return apiClient.post(this.urlStart + `demo-users/make-live/${userId}`);
  },

  getDemoUsers() {
    return apiClient.get(this.urlStart + `demo-users`);
  },

  getUserById(userId) {
    return apiClient.get(this.urlStart + `user/${userId}`);
  },

  getUserInfo(userId) {
    return apiClient.get(this.urlStart + `user/info/${userId}`);
  },

  getSoloUserForEditing(params) {
    return apiClient.get(this.urlStart + `user/${params.userId}/for-editing`);
  },

  getGroupUserForEditing(params) {
    return apiClient.get(this.urlStart + `user/${params.userId}/group/${params.groupId}/for-editing`);
  },

  allocateLicenceToUser(licence, userId) {
    let params = {
      courseId: licence.course.id,
      transactionRef: licence.transactionRef,
      certificateMonths: licence.certificateMonths
    };

    return apiClient.post(this.urlStart + `user-licences/${userId}`, params);
  },

  postBulkIssueLicenceFromGeneralPot(groupId, abstractCourseId, users, certificateMonths) {
    return apiClient.post(this.urlStart + `group-licences/${groupId}/bulk-issue/abstract-course/${abstractCourseId}`, {
      users,
      certificateMonths
    });
  },

  postBulkIssueLicenceFromGeneralPotToSubgroupMembers(subgroupId, abstractCourseId, users, certificateMonths) {
    return apiClient.post(
      this.urlStart + `subgroup-licences/${subgroupId}/bulk-issue/abstract-course/${abstractCourseId}`,
      {
        users,
        certificateMonths
      }
    );
  },

  postBulkIssueLicenceFromPoolToSubgroupMembers(subgroupId, abstractCourseId, users, certificateMonths) {
    return apiClient.post(
      this.urlStart + `subgroup-pool-licences/${subgroupId}/bulk-issue/abstract-course/${abstractCourseId}`,
      {
        users,
        certificateMonths
      }
    );
  },

  allocateGeneralPotLicenceToSubgroupUser(subgroupId, userId, abstractCourseId, certificateMonths) {
    return apiClient.post(
      this.urlStart + `subgroup-licences/${subgroupId}/user/${userId}/abstract-course/${abstractCourseId}`,
      {
        certificateMonths
      }
    );
  },

  allocatePoolLicenceToSubgroupUser(subgroupId, userId, abstractCourseId, certificateMonths) {
    return apiClient.post(
      this.urlStart + `subgroup-pool-licences/${subgroupId}/user/${userId}/abstract-course/${abstractCourseId}`,
      {
        certificateMonths
      }
    );
  },

  allocateGeneralPotLicenceToVirtualGroupUser(virtualGroupId, userId, abstractCourseId) {
    return apiClient.post(
      this.urlStart + `virtual-group-licences/${virtualGroupId}/user/${userId}/abstract-course/${abstractCourseId}`
    );
  },

  allocatePoolLicenceToVirtualGroupUser(virtualGroupId, userId, abstractCourseId) {
    return apiClient.post(
      this.urlStart + `virtual-group-pool-licences/${virtualGroupId}/user/${userId}/abstract-course/${abstractCourseId}`
    );
  },

  updateUserPersonalDetails(params) {
    return apiClient.patch(this.urlStart + `user-personal-details`, params);
  },

  updateUserLoginDetails(params) {
    return apiClient.patch(this.urlStart + `user-login-details`, params);
  },

  updateUser(params, userId, groupId) {
    if (groupId) {
      return apiClient.post(this.urlStart + `user/${userId}/group/${groupId}`, params);
    } else {
      return apiClient.post(this.urlStart + `user/${userId}`, params);
    }
  },

  updateUserPhoto(userId, formData) {
    return apiClient.post(this.urlStart + `user-photo/${userId}`, formData);
  },

  updateUserLoginWithEmail(params, userId) {
    return apiClient.post(this.urlStart + `user/${userId}/with-email`, params);
  },

  updateUserLoginWithUsername(params, userId) {
    return apiClient.post(this.urlStart + `user/${userId}/with-username`, params);
  },

  filterUsers(filters) {
    return apiClient.post(this.urlStart + `users/filtered`, filters);
  },

  postGroup(grouping) {
    return apiClient.post(this.urlStart + `grouping`, grouping);
  },

  createNewPled(groupId, newLicence, subgroupId = null, directServiceOrder = 0) {
    let params = {
      assessment_type: newLicence.assessmentType,
      quantity: newLicence.quantity,
      delegatePlacesAllowed: newLicence.delegatePlacesAllowed,
      postTrainingDaysAllowed: newLicence.postTrainingDaysAllowed,
      transactionRef: newLicence.transactionRef,
      poReference: newLicence.poReference,
      certificateMonths: newLicence.certificateMonths,
      trainingAt: newLicence.training_at,
      trainingSlot: newLicence.training_slot,
      trainingStart: newLicence.training_start,
      trainingEnd: newLicence.training_end,
      trainerUserId: newLicence.trainerUserId,
      directServiceOrder: directServiceOrder,
      venueId: newLicence.venue.id,
      zoomMeetingId: newLicence.zoomMeetingId,
      zoomUrl: newLicence.zoomUrl,
      zoomPasscode: newLicence.zoomPasscode,
      zoomAccountId: newLicence.zoomAccountId,
      organiserUserId: newLicence.organiserUserId
    };

    if (subgroupId) {
      params.subgroupId = subgroupId;
    }

    if (newLicence.course.id) {
      return apiClient.post(this.urlStart + `virtual-group/${groupId}/course/${newLicence.course.id}`, params);
    } else {
      return apiClient.post(
        this.urlStart + `virtual-group/${groupId}/paper-course/${newLicence.paperCourse.id}`,
        params
      );
    }
  },

  updatePled(virtualGroup) {
    let params = {
      course_id: virtualGroup.course && virtualGroup.course.id ? virtualGroup.course.id : null,
      paper_course_id: virtualGroup.paperCourse && virtualGroup.paperCourse.id ? virtualGroup.paperCourse.id : null,

      assessment_type: virtualGroup.assessment_type,

      quantity: virtualGroup.quantity,
      delegate_places_allowed: virtualGroup.delegate_places_allowed,
      post_training_days_allowed: virtualGroup.post_training_days_allowed,

      training_at: virtualGroup.training_at,
      training_slot: virtualGroup.training_slot,
      training_start: virtualGroup.training_start,
      training_end: virtualGroup.training_end,
      trainer_user_id: virtualGroup.trainer_user_id,

      venue_id: virtualGroup.venue ? virtualGroup.venue.id : null,
      zoom_meeting_id: virtualGroup.zoom_meeting_id,
      zoom_url: virtualGroup.zoom_url,
      zoom_passcode: virtualGroup.zoom_passcode,
      zoom_account_id: virtualGroup.zoom_account_id,
      organiser_user_id: virtualGroup.organiser_user_id
    };

    return apiClient.patch(this.urlStart + `virtual-group/${virtualGroup.id}`, params);
  },

  postGroupLicencePurchase(groupId, newLicence, subgroupId = null, directServiceOrder = 0) {
    let params = {
      quantity: newLicence.quantity,
      transactionRef: newLicence.transactionRef,
      poReference: newLicence.poReference,
      certificateMonths: newLicence.certificateMonths,
      directServiceOrder: directServiceOrder
    };

    if (newLicence.licenceRequestId) {
      params.licenceRequestId = newLicence.licenceRequestId;
    }

    if (subgroupId) {
      params.subgroupId = subgroupId;
    }

    if (newLicence.course.id) {
      return apiClient.post(
        this.urlStart + `group-licences/${groupId}/course/${newLicence.course.id}/purchase`,
        params
      );
    } else {
      return apiClient.post(
        this.urlStart + `group-licences/${groupId}/paper-course/${newLicence.paperCourse.id}/purchase`,
        params
      );
    }
  },

  returnPoolToTheGeneralPot(licencePoolId) {
    return apiClient.post(this.urlStart + `group-licences/reclaim-pool-licences/${licencePoolId}`);
  },

  getSubgroups() {
    return apiClient.get(this.urlStart + `subgroups`);
  },

  getGroupsArchivedSubgroups(groupId) {
    return apiClient.get(this.urlStart + `group/${groupId}/archived-subgroups`);
  },

  getVirtualGroups() {
    return apiClient.get(this.urlStart + `virtual-groups`);
  },

  updateTrainersGeneralAvailabilityRules(trainerId, params) {
    return apiClient.post(this.urlStart + `trainer-general-availability-rules/${trainerId}`, params);
  },

  getGeneralAvailabilityRules() {
    return apiClient.get(this.urlStart + `general-availability-rules`);
  },

  getTrainersGeneralAvailabilityRules(trainerId) {
    return apiClient.get(this.urlStart + `trainer-general-availability-rules/${trainerId}`);
  },

  getGeneralAvailabilityCalendar(trainerId = null) {
    let url = this.urlStart + `general-availability-calendar`;
    if (trainerId) {
      url += `/${trainerId}`;
    }

    return apiClient.get(url);
  },

  getCalendarProviders() {
    return apiClient.get(this.urlStart + `calendar-providers`);
  },

  getCalendarProviderSubscriptions() {
    return apiClient.get(this.urlStart + `calendar-provider-subscriptions`);
  },

  calendarProviderSubscribe(calendarProviderId, email) {
    return apiClient.post(this.urlStart + `calendar-provider-subscriptions/${calendarProviderId}/subscribe`, { email });
  },

  calendarProviderUnsubscribe(calendarProviderId) {
    return apiClient.post(this.urlStart + `calendar-provider-subscriptions/${calendarProviderId}/unsubscribe`);
  },

  getGeneralAvailabilitySchedule() {
    return apiClient.get(this.urlStart + `general-availability-schedule`);
  },

  updateGeneralAvailabilityCalendar(days, amStatus, pmStatus, trainerId = null) {
    let params = {
      dates: days,
      amStatus,
      pmStatus
    };

    let url = this.urlStart + `general-availability-calendar`;
    if (trainerId) {
      url += `/${trainerId}`;
    }

    return apiClient.post(url, params);
  },

  getTrainersAvailabilityEnquiries() {
    return apiClient.get(this.urlStart + `availability-enquiries`);
  },

  getTrainerBookingConfirmationEnquiries() {
    return apiClient.get(this.urlStart + `booking-confirmation-enquiries`);
  },

  confirmTrainerBookingConfirmationEnquiry(enquiryId) {
    return apiClient.post(this.urlStart + `booking-confirmation-enquiries/${enquiryId}/confirm`);
  },

  rejectTrainerBookingConfirmationEnquiry(enquiryId) {
    return apiClient.post(this.urlStart + `booking-confirmation-enquiries/${enquiryId}/reject`);
  },

  confirmTrainerTwoInOneDayBookingConfirmationEnquiries(enquiryIds) {
    return apiClient.post(this.urlStart + `two-in-one-day-booking-confirmation-enquiries/confirm`, { enquiryIds });
  },

  rejectTrainerTwoInOneDayBookingConfirmationEnquiries(enquiryIds) {
    return apiClient.post(this.urlStart + `two-in-one-day-booking-confirmation-enquiries/reject`, { enquiryIds });
  },

  confirmTrainerMultiDayBookingConfirmationEnquiries(enquiryIds) {
    return apiClient.post(this.urlStart + `multi-day-booking-confirmation-enquiries/confirm`, { enquiryIds });
  },

  rejectTrainerMultiDayBookingConfirmationEnquiries(enquiryIds, problemEnquiryIds, hasOtherProblem) {
    return apiClient.post(this.urlStart + `multi-day-booking-confirmation-enquiries/reject`, {
      enquiryIds,
      problemEnquiryIds,
      hasOtherProblem
    });
  },

  saveTrainersAvailabilityEnquiryChoice(params) {
    return apiClient.post(this.urlStart + `availability-enquiries`, params);
  },

  getRecentlyCompletedVirtualGroups() {
    return apiClient.get(this.urlStart + `recently-completed-virtual-groups`);
  },

  getHistoricVirtualGroupInfo(virtualGroupId) {
    return apiClient.get(this.urlStart + `virtual-group/${virtualGroupId}/historic-info`);
  },

  saveFeedbackTrainerScore(virtualGroupUserId, score) {
    return apiClient.patch(this.urlStart + `virtual-group-user/${virtualGroupUserId}/feedback-trainer-score`, {
      score
    });
  },

  saveFeedbackCourseScore(virtualGroupUserId, score) {
    return apiClient.patch(this.urlStart + `virtual-group-user/${virtualGroupUserId}/feedback-course-score`, {
      score
    });
  },

  saveFeedbackMoreConfident(virtualGroupUserId, answer) {
    return apiClient.patch(this.urlStart + `virtual-group-user/${virtualGroupUserId}/feedback-more-confident`, {
      answer
    });
  },

  saveFeedbackGeneral(virtualGroupId, feedback) {
    return apiClient.patch(this.urlStart + `virtual-group/${virtualGroupId}/feedback`, {
      feedback
    });
  },

  storeUsersFeedback(virtualGroupId, users) {
    return apiClient.post(this.urlStart + `virtual-group/${virtualGroupId}/users-feedback`, {
      users
    });
  },

  storeGeneralFeedback(virtualGroupId, feedback) {
    return apiClient.post(this.urlStart + `virtual-group/${virtualGroupId}/general-feedback`, {
      feedback
    });
  },

  getSubgroupsLicencePools(subgroupId) {
    return apiClient.get(this.urlStart + `subgroup/${subgroupId}/licence-pools`);
  },

  getVirtualGroupsLicencePools(virtualGroupId) {
    return apiClient.get(this.urlStart + `virtual-group/${virtualGroupId}/licence-pools`);
  },

  groupsMembersWithVirtualGroupMembershipStatus(groupId, virtualGroupId) {
    return apiClient.get(this.urlStart + `group/${groupId}/virtual-group/${virtualGroupId}/membership-status`);
  },

  postVirtualGroupMembershipStatus(virtualGroupId, users) {
    return apiClient.post(this.urlStart + `virtual-group-membership/${virtualGroupId}`, {
      users
    });
  },

  getVirtualGroupsUsers(virtualGroupId) {
    return apiClient.get(this.urlStart + `virtual-group/${virtualGroupId}/users`);
  },

  getVirtualGroupsUsersForSubgroupManager(params) {
    return apiClient.get(this.urlStart + `virtual-group/${params.virtualGroupId}/${params.subgroupId}/users`);
  },

  //This could be a group, sub or vg so we cant use route injection
  patchGrouping(grouping) {
    return apiClient.patch(this.urlStart + `grouping`, grouping);
  },

  addUserToGroup(groupId, userId) {
    return apiClient.post(this.urlStart + `group/${groupId}/add-existing-user/${userId}`);
  },

  addUserToSubgroup(subgroupId, userId) {
    return apiClient.post(this.urlStart + `subgroup/${subgroupId}/add-existing-user/${userId}`);
  },

  addUserToVirtualGroup(virtualGroupId, userId) {
    return apiClient.post(this.urlStart + `virtual-group/${virtualGroupId}/add-existing-user/${userId}`);
  },

  addArchivedUserToGroup(groupId, userId) {
    return apiClient.post(this.urlStart + `group/${groupId}/add-existing-archived-user/${userId}`);
  },

  addArchivedUserToSubgroup(subgroupId, userId) {
    return apiClient.post(this.urlStart + `subgroup/${subgroupId}/add-existing-archived-user/${userId}`);
  },

  inviteUserToGroupAndSubgroup(subgroupId, userId) {
    return apiClient.post(this.urlStart + `subgroup/${subgroupId}/invite-existing-user/${userId}`);
  },

  inviteUserToGroup(groupId, userId) {
    return apiClient.post(this.urlStart + `group/${groupId}/invite-existing-user/${userId}`);
  },

  deleteVirtualGroup(virtualGroupId) {
    return apiClient.delete(this.urlStart + `virtual-group/${virtualGroupId}`);
  },

  getGroupSettings(groupId) {
    return apiClient.get(this.urlStart + `group/${groupId}/settings`);
  },

  checkForServiceManagerPleds(groupId) {
    return apiClient.get(this.urlStart + `group/${groupId}/check-for-service-manager-pleds`);
  },

  saveGroupSettings(groupId, params) {
    return apiClient.post(this.urlStart + `group/${groupId}/settings`, params);
  },

  getPersonalSettings() {
    return apiClient.get(this.urlStart + `user/settings`);
  },

  savePersonalSettings(params) {
    return apiClient.post(this.urlStart + `user/settings`, params);
  },

  getCoursesMissingSvgImages() {
    return apiClient.get(this.urlStart + `courses/courses-missing-svg-images`);
  },

  getMessagesAwaitingProofReading() {
    return apiClient.get(`/message-previews/awaiting-proof-reading`);
  },

  getPledsMissingTrainingTime() {
    return apiClient.get(this.urlStart + `courses/missing-training-times`);
  },

  /* login stats */
  getMostActiveOrgs(period) {
    return apiClient.get(this.urlStart + `login-stats/most-active-orgs/${period}`);
  },

  getLinkedAbstractCourses() {
    return apiClient.get(this.urlStart + `linked-abstract-courses`);
  },

  deleteLinkedAbstractCourseId(linkedAbstractCoursesId) {
    return apiClient.delete(this.urlStart + `linked-abstract-courses/${linkedAbstractCoursesId}`);
  },

  saveLinkedAbstractCourses(params) {
    return apiClient.post(this.urlStart + `linked-abstract-courses`, params);
  }
};
