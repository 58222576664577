<template>
  <div>
    <transition name="fade">
      <!-- prettier-ignore-attribute class -->
      <div
        v-if="flashMessage.message"
        class="fixed top-0 right-0 left-0 z-80 p-4 text-2xl opacity-90 shadow-md 
        flex items-center justify-center text-center text-white box-height"
        :class="flashClass"
        @click.prevent.stop="$store.commit('clearFlashMessage')">
        {{ flashMessage.message }}
        <div class="absolute top-2 right-2 w-8 h-8 border border-white flex items-center justify-center">x</div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  computed: {
    flashMessage() {
      return this.$store.state.flashMessage;
    },
    flashClass() {
      if (this.flashMessage.status === "error") {
        return "bg-red-500";
      } else if (this.flashMessage.status === "success") {
        return "bg-success";
      } else {
        return "bg-yellow-500";
      }
    }
  }
};
</script>
<style scoped>
.box-height {
  height: 20vh;
}
</style>
